console.log("✅ Vérification dans base.js");
window.editEntite = window.editEntite || function() {};
window.crossEntity = window.crossEntity || function() {};



// console.log(domid, entite);
$.tabswitch(domid, entite, domaine, trans, tdb); 
if ( id !== 'undefined' && id !== null && id !== '' ) {editEntite(id, entite);}

$(window).resize(function()  {
    var width = $(window).width();
    if (width < 980 && volet_edit=='1'){
        console.log('Your screen is smaller than 980');
        document.getElementById("mySidebar").style.width = "100%";
        document.getElementById("main").style.marginRight = "100%";
    }
    if (width > 980 && volet_edit=='1'){
        console.log('Your screen is bigger than 980');
        document.getElementById("mySidebar").style.width = "580px";
        document.getElementById("main").style.marginRight = "580px";
    }
});

var volet_edit = '0';
function openNav() {
    volet_edit='1';
    var width = $(window).width();
    if (width < 980 && volet_edit=='1'){
        console.log('Your screen is smaller than 980');
        document.getElementById("mySidebar").style.width = "100%";
        document.getElementById("main").style.marginRight = "100%";
    }
    if (width > 980 && volet_edit=='1'){
        console.log('Your screen is bigger than 980');
        document.getElementById("mySidebar").style.width = "700px";
        document.getElementById("main").style.marginRight = "700px";

        // $('.nav-tabs').responsiveTabs();
    }
}

/* Set the width of the sidebar to 0 and the left margin of the page content to 0 */
function closeNav(entite) {
$("#overlay").fadeIn(300);
document.getElementById("mySidebar").style.width = "0";
document.getElementById("main").style.marginRight = "0";
$('#showbar').html('');
$("#overlay").fadeOut(300);
//$('#dataTables-'+entite).DataTable().ajax.reload();
updateDataTable();
//console.log(entite);

// $('.nav-tabs').responsiveTabs();
volet_edit='O';


}



document.addEventListener('click', function (e) {
    if (e.target.matches('a[onclick^="crossEntity"]')) {
        e.preventDefault();
        const id = e.target.getAttribute('onclick').match(/crossEntity\((\d+),/)[1];
        const type = e.target.getAttribute('onclick').match(/'([^']+)'/)[1];
        crossEntity(id, type);
    }
});




window.editEntite = function (id, entite) {
    console.log("✅ [editEntite] Appelée avec :", id, entite);
    
    $("#overlay").fadeIn(300);

    var pathArray = window.location.pathname.split('/');
    var domid = pathArray[2];
    var tdb = pathArray[3];
    console.log("🔗 URL Extraction -> Domaine:", domid, "TDB:", tdb);

    var url = (id !== 'undefined')
        ? '/app/' + entite + '/ajaxedit/' + id + '/' + entite
        : '/app/' + entite + '/ajaxcreate/' + domid;
    console.log("📡 [editEntite] Requête AJAX vers :", url);

    $.ajax({
        url: url,
        type: 'POST',
        dataType: 'json',
        async: true,
        success: function (data) {
            console.log("✅ [editEntite] Succès AJAX, affichage...");
            openNav();
            $('#showbar').html('');
            $('#showbar').append(data.output);
            $("#overlay").fadeOut(300);
            updateDataTable();
            initializeSliders();
        },
        error: function () {
            console.error("❌ [editEntite] Erreur AJAX !");
            alert('Vous ne pouvez pas accéder à cette ressource. Contactez votre administrateur.');
            $("#overlay").fadeOut(300);
        }
    });
};


window.crossEntity = function crossEntity(id, entite) {
    editEntite(id, entite);
};







function updateDataTable() {
    console.log("Running updateDataTable...");

    // Vérifier et afficher les tables visibles
    const visibleTables = $.fn.dataTable.tables({ visible: true });
    // console.log(`Visible tables:`, visibleTables);

    visibleTables.forEach((table) => {
        const tableId = $(table).attr("id");
        console.log(`Refreshing visible DataTable: ${tableId}`);

        if ($.fn.DataTable.isDataTable(`#${tableId}`)) {
            // Recharge les données sans réinitialiser la pagination
            $(table).DataTable().ajax.reload(null, false);
        } else {
            console.warn(`Table ${tableId} is not initialized as a DataTable.`);
        }
    });

    // Vérifier et ajuster les tables masquées
    const hiddenTables = $.fn.dataTable.tables({ visible: false });
    // console.log(`Hidden tables:`, hiddenTables);

    hiddenTables.forEach((table) => {
        const tableId = $(table).attr("id");
        console.log(`Adjusting hidden DataTable: ${tableId}`);
        if ($.fn.DataTable.isDataTable(`#${tableId}`)) {
            $(table).DataTable().columns.adjust();
        }
    });

    // Ajuster les colonnes lorsque des conteneurs masqués deviennent visibles
    $(".hidden-container").on("shown.bs.collapse", function() {
        console.log("Container became visible, adjusting tables...");
        const newlyVisibleTables = $.fn.dataTable.tables({ visible: true });
        newlyVisibleTables.forEach((table) => {
            $(table).DataTable().columns.adjust();
        });
    });

    console.log("DataTable update process completed.");
}

    


// Définir la fonction crossEntity dans le scope global
window.leaveEntity = function leaveEntity(entite)  {
    closeNav(entite);
};


window.submitAjax = function(event, id, entite) {
    if (event) event.preventDefault(); // Empêche le comportement par défaut du bouton
    $("#overlay").fadeIn(300);
    var pathArray = window.location.pathname.split('/');
    var domid = pathArray[2];
    var tdb = pathArray[3];
    console.log('domaine ' + domid + ' tdb ' + tdb);

    var url = id !== 'undefined'
        ? '/app/' + entite + '/ajaxedit/' + id + '/' + entite
        : '/app/' + entite + '/ajaxcreate';

    console.log('URL : ' + url);

    $.ajax({
        url: url,
        method: 'POST',
        dataType: 'json',
        data: $("form[name='" + entite + "']").serialize(),
        async: true,
        success: function (data) {
            $('#showbar').html('');
            $('#showbar').append(data.output);
            sform_clean = $("form").serialize();
            $("#overlay").fadeOut(300);
            updateDataTable();
            initializeSliders();
            
        },
        error: function () {
            alert('Vous ne pouvez pas effectuer cette action, veuillez contacter votre administrateur.');
            $("#overlay").fadeOut(30);
        }
    });
};


window.deleteEntity = function(event, id, entite)  {
    if (event) event.preventDefault();
    $("#overlay").fadeIn(300);
    var r = confirm("Voulez vous vraiment supprimer cette fiche ? Toutes vos données seront supprimées");
    if (r == true) { 
    
    var url = '/app/remove/'+id+'/'+entite;
    console.log(url);
    $.ajax({  
        url:        url,  
        type:       'POST',   
        dataType:   'json', 
        async:      true,  
        success: function( data, status, output) {  
                $('#showbar').html('');
                closeNav(entite);
                updateDataTable();
                $("#overlay").fadeOut(300);
                toastr.success('Suppression de la fiche', 'Succès')
        },  
        error : function(xhr, textStatus, errorThrown) {  
            console.log('error'); 
            alert('L\'élément n\'a pas été supprimé'); 
            
            $("#overlay").fadeOut(300); 
        }  
    });      
    
    
    } else {$("#overlay").fadeOut(300); }

}

window.resetPassword = function(id)  {
    event.preventDefault();
    $("#overlay").fadeIn(300);
    var r = confirm("Voulez vous vraiment réinitialiser le mot de passe de cet utilisateur ?");
    if (r == true) { 
    
    var url = '/app/admin/user/'+id+'/init/password';
    console.log(url);
    $.ajax({  
        url:        url,  
        type:       'POST',   
        dataType:   'json', 
        async:      true,  
        success: function( data, status, output) {  
            $("#overlay").fadeOut(300);
            toastr.success('Le mot de passe est généré. Un email est envoyé à l\'utilisateur', 'Succès')
        },  
        error : function(xhr, textStatus, errorThrown) {  
            console.log('error'); 
            toastr.error('Le mot de passe n\'est pas réinitialisé', 'Erreur')
            
            $("#overlay").fadeOut(300); 
        }  
    });      
    } else {$("#overlay").fadeOut(300); }
}


/* Function recevant les demandes d'ouverture d'une fiche afin de verifier que la fiche actuelle ne nécessitent pas d'enregistrer avant de la quitter*/
// function crossEntity(id, entite)  {
//     event.preventDefault();
//     var used_submit = 0;
//     var sform_dirty = $("form[name='"+entite+"']").serialize();
//     if(volet_edit=='1' && sform_clean != sform_dirty && used_submit ==0) {
//         console.log('Changement sur le formulaire');
//         var r = confirm("Attention!\nVous avez des modifications non enregistrées, Voulez vous vraiment quitter cette fiche ?");
//         if (r == true) { editEntite(id, entite);} else { }
//     } else { editEntite(id, entite); console.log('Pas de changement sur le form'); console.log(entite+id);
//     };
//     function CleanLeavePage(){used_submit = 1;}
// } 



    


toastr.options = {
"progressBar": true,
"positionClass": "toast-top-center",
}


// {% for message in app.flashes('success') %}  
//     toastr.success('{{ message }}')
// {% endfor %}
// {% for message in app.flashes('warning') %}  
//     toastr.warning('{{ message }}')
// {% endfor %}	

// $(function() {
//     $('.nav-tabs').responsiveTabs();
// });


