// Console log pour vérifier que le fichier est chargé

console.log("app.js is loaded and running!");
import $ from 'jquery';
global.$ = global.jQuery = $;
window.jQuery = $;

console.log('jQuery version:', $.fn.jquery);


// Importation de Bootstrap 5

import * as bootstrap from 'bootstrap';
window.bootstrap = bootstrap;
if (typeof bootstrap === 'undefined') {
    console.log('Bootstrap n’est pas chargé. Vérifiez votre configuration.');
} else {
    console.log('Bootstrap chargé avec succès.');
}



import 'bootstrap/dist/css/bootstrap.min.css';
/* Function recevant les demandes de navigation sur le div main */
import 'bootstrap/dist/js/bootstrap.bundle'; // Inclut le support de jQuery pour Bootstrap

require('./js/switch');
require('./js/jquery-confirm-trigger');
require('./js/base');
require('./js/appoffline');
require('./js/baseform');
require('./js/workflowbutton');

// Importation des fichiers CSS/SCSS
import "./css/app.scss"; // Styles principaux
import "./css/stylev2.css"; // Styles additionnels
import "./css/mystyle.css"; // Styles personnalisés


import 'jquery-ui';
import 'jquery-ui/ui/widgets/sortable';
import 'jquery-ui/ui/disable-selection';
import 'jquery-confirm';
import '@popperjs/core';


import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list'; // ✅ Import correct
import bootstrapPlugin from '@fullcalendar/bootstrap';

window.FullCalendar = {
    Calendar,
    listPlugin, // ✅ Ajout explicite pour vérification
    plugins: [dayGridPlugin, listPlugin, bootstrapPlugin] // ✅ Ajouté dans la liste des plugins
};

console.log("FullCalendar plugins chargés");



import { DataSet, Timeline } from "vis-timeline/standalone";
import { Network } from "vis-network/standalone";

window.vis = {
    DataSet,
    Timeline,
    Network
};

console.log("Vis.js chargé");





// Importer ionRangeSlider et ses styles
import 'ion-rangeslider/js/ion.rangeSlider.min';
import 'ion-rangeslider/css/ion.rangeSlider.min.css';

// Importation de Sparkline (si nécessaire)
import 'jquery-sparkline';

// Initialisation dans un `document.ready`
$(document).ready(() => {
    if ($('.sparklines').length > 0) {
        $('.sparklines').sparkline('html', { enableTagOptions: true });
    } else {
    }
});

// DataTables Core et extensions Bootstrap 5
import 'datatables.net-bs5';
import 'datatables.net-buttons-bs5';
import 'datatables.net-select-bs5';
import 'datatables.net-searchpanes-bs5';
import 'datatables.net-buttons/js/buttons.colVis.js';
import 'datatables.net-buttons/js/buttons.flash.js';
import 'datatables.net-buttons/js/buttons.html5.js';
import 'datatables.net-buttons/js/buttons.print.js';
import 'datatables.net-responsive-bs5';
import 'datatables.net-colreorder-bs5';
import 'datatables.net-fixedheader-bs5';

// Importation de Chart.js
import 'chart.js';
import 'chartjs-plugin-colorschemes';


// Importation de Vis.js
import 'vis-network';

// Importation de Toastr
import toastr from 'toastr';
import 'toastr/build/toastr.min.css'; 
window.toastr = toastr;

toastr.options = {
    closeButton: true,
    progressBar: true,
    positionClass: 'toast-top-right',
    timeOut: '3000',
};


window.JSZip = require('jszip')

import 'select2';
import 'select2/dist/css/select2.min.css';
import 'select2-bootstrap-5-theme/dist/select2-bootstrap-5-theme.min.css';

// Exemple d'initialisation pour les composants Bootstrap 5
import { Tab } from 'bootstrap';

// Activation des onglets Bootstrap 5
document.querySelectorAll('.nav-tabs a').forEach(tabElement => {
    tabElement.addEventListener('click', event => {
        event.preventDefault();
        const tabInstance = new Tab(tabElement);
        tabInstance.show();
    });
});

// document.querySelectorAll('.dropdowntable.dropstart').forEach(function (dropdown) {
//     dropdown.addEventListener('mouseenter', function () {
//         this.classList.add('show');
//         const menu = this.querySelector('.dropdown-menu');
//         if (menu) {
//             menu.classList.add('show');
//             menu.style.display = 'block'; // Assure l'affichage
//         }
//     });

//     dropdown.addEventListener('mouseleave', function () {
//         this.classList.remove('show');
//         const menu = this.querySelector('.dropdown-menu');
//         if (menu) {
//             menu.classList.remove('show');
//             menu.style.display = 'none'; // Assure le masquage
//         }
//     });
// });





